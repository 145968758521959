import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import { Layout } from '@app/components';

import '@app/styles/pages/pageTemplate.scss';

type PageTemplatePropTypes = {
  data: {
    markdownRemark: {
      frontmatter: {
        title: string;
      };
      html: string;
    };
  };
};

const PageTemplate: React.FC<PageTemplatePropTypes> = ({ data }) => {
  const { markdownRemark } = data;

  if (markdownRemark === null) {
    return null;
  }

  const { frontmatter, html } = markdownRemark;

  return (
    <Layout>
      <Helmet title={frontmatter.title} />
      <div className="page-hero">
        <div className="page-hero__bg">
          <div className="page-hero__bg-overlay" />
          <div className="page-hero__bg-boxed">
            <div className="page-hero__bg-mask" />
            <div className="page-hero__bg-mask-bg" />
          </div>
        </div>
        <div className="page-hero__title">
          <div className="container mx-auto px-10 lg:px-1.5">
            <h2>{frontmatter.title}</h2>
          </div>
        </div>
      </div>
      <div className="page-content">
        <div
          className="container mx-auto px-10 lg:px-1.5"
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </div>
    </Layout>
  );
};

export default PageTemplate;

export const pageQuery = graphql`
  query ($slug: String!, $lang: String!) {
    markdownRemark(frontmatter: { path: { eq: $slug }, lang: { eq: $lang } }) {
      id
      frontmatter {
        lang
        path
        title
      }
      html
    }
    locales: allLocale(filter: { language: { eq: $lang } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
